import React from "react"

const Footer = () => (
  <footer>
        {/* <div className="footer-logo-wrapper">
            <div className="footer-logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46" fill="currentColor"><title>Netclix N Device</title><rect width="56.69" height="28.35" /><rect y="85.04" width="56.69" height="56.69" /><rect y="170.08" width="56.69" height="113.39" /><rect x="85.04" y="28.35" width="56.69" height="56.69" /><rect x="85.04" y="141.73" width="56.69" height="28.35" /><rect x="226.77" y="255.12" width="56.69" height="28.35" /><rect x="226.77" y="141.73" width="56.69" height="56.69" /><rect x="226.77" width="56.69" height="113.39" /><rect x="141.73" y="198.43" width="56.69" height="56.69" /><rect x="141.73" y="113.39" width="56.69" height="28.35" /></svg>
            </div>
        </div> */}
        <div className="copyright">Copyright &copy; 2012-{new Date().getFullYear()} Orgasmic Ltd. All rights reserved. <br />GoJohnnie and GoJohnny are Trademarks/Registered Trademarks of Orgasmic Ltd.</div>
        <div className="company-info">
            <p>Registered Office: Acorn House, Keys Park Road, Cannock, WS12 2FZ, United Kingdom.</p>
            <p>Registered in England and Wales 1999. Company Registration Number: 03844011.</p>
        </div>
    </footer>
)

export default Footer
